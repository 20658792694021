import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';
import { Icon, IconName } from '../Icon/Icon';

const variants = ['filled', 'outline'] as const;
const size = ['sm', 'md', 'lg'] as const;

type Variant = typeof variants[number];
type Size = typeof size[number];

const styles = {
    container: (variant: Variant, isSelected: boolean, isDisabled: boolean) => {
        let variantStyle = Cn.c('');

        switch (variant) {
            case 'filled':
                if (isDisabled) {
                    variantStyle = Cn.c('bg-surface-disabled text-disabled border-0');
                } else if (isSelected) {
                    variantStyle = Cn.c('bg-surface-primary-default text-primary-default border-0');
                } else {
                    variantStyle = Cn.c('bg-surface-dark-emphasized text-on-primary border-0');
                }

                break;
            case 'outline':
                if (isDisabled) {
                    variantStyle = Cn.c('text-disabled border border-subdued');
                } else if (isSelected) {
                    variantStyle = Cn.c('text-primary-default border border-primary-emphasized');
                } else {
                    variantStyle = Cn.c('text-emphasized border border-dark');
                }
                break;
        }

        return Cn.join([
            Cn.c('flex items-center space-x-2 border rounded-2xl px-3 py-1 w-fit'),
            variantStyle,
        ]);
    },
    label: (size: Size) => {
        switch (size) {
            case 'sm':
                return Cn.c('font-paragraph-xsmall-regular');
            case 'md':
                return Cn.c('font-paragraph-small-regular');
            case 'lg':
                return Cn.c('font-paragraph-base-regular');
        }
    },
    closeIcon: (size: Size) => {
        switch (size) {
            case 'sm': return Cn.c('w-3 h-3');
            case 'md': return Cn.c('w-3.5 h-3.5');
            case 'lg': return Cn.c('w-4 h-4');
        }
    },
    icon: (size: Size, variant: Variant, isDisabled: boolean, isSelected: boolean) => {
        let variantStyle = Cn.c('');

        if (isDisabled) {
            variantStyle = Cn.c('text-icons-subdued');
        } else if (isSelected) {
            variantStyle = Cn.c('text-icons-primary-default');
        } else {
            switch (variant) {
                case 'filled':
                    variantStyle = Cn.c('text-icons-on-primary');
                    break;
                case 'outline':
                    variantStyle = Cn.c('text-icons-emphasized');
                    break;
            }
        }

        return Cn.join([
            size === 'sm' ? Cn.c('w-3.5 h-3.5') : Cn.c('w-4 h-4'),
            variantStyle,
        ]);
    },
}

interface Props {
    label: string;
    onRemove?: (label: string) => void;
    isSelected?: boolean;
    isDisabled?: boolean;
    variant?: Variant;
    size?: Size;
    className?: string;
    /**  Icon of the chip */
    iconName?: IconName;
    /**  Custom class names for the icon */
    iconClassName?: string;
}

const Chip: FunctionComponent<Props> = ({
    label,
    onRemove,
    isSelected = false,
    isDisabled = false,
    variant = 'filled',
    size = 'md',
    className,
    iconName,
    iconClassName,
}) => {
    return (
        <div className={Cn.join([
            styles.container(variant, isSelected, isDisabled),
            Cn.getSome(className)])}>
            {isDefined(iconName) && <Icon name={iconName}
                className={Cn.join([
                    styles.icon(size, variant, isDisabled, isSelected),
                    Cn.getSome(iconClassName)])}
            />}
            <p
                key={label}
                title={label}
                className={styles.label(size)}
            >{label}</p>
            {onRemove && <Icon
                name="close"
                onClick={() => onRemove(label)}
                className={styles.closeIcon(size)}
            />}
        </div>
    )
}

export {
    Chip, size, variants
};

