import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Button } from '@shared/unsorted/Button/Button';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionByStatus } from './hook';

interface Props {
    currentStepStatus: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusV2'>;
    jobPosition: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.jobPosition'>;
}

const ActionByStatus: FunctionComponent<Props> = ({ currentStepStatus, jobPosition }) => {
    const { t } = useTranslation();

    const { moveToJobPosition } = useActionByStatus(jobPosition);

    if (currentStepStatus === 'SCHEDULE_ADJUSTMENT') {
        return (
            <Button
                size="md"
                onClick={() => moveToJobPosition()}
            >
                {t("applications.panel.scheduleEvent")}
            </Button>
        );
    }

    // TODO: [CHECK] Pending confirmation from Design, 'hiding' the main action button is the option for now. Once decided, remove this comment.
    return null; // No main action available for the current step status
};

export {
    ActionByStatus,
};