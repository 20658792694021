import { useNavigate } from "react-router-dom";

export const useBackToPreviousListPage = () => {
    const navigate = useNavigate();

    //TODO: update the logic to get all the previous list page's search params
    const backToPreviousListPage = () => navigate(-1);

    return {
        backToPreviousListPage
    }
}