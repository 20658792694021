import { Cn } from '@helpers/unsorted/classNames';
import { DefaultLayout } from '@shared/layout/DefaultLayout/DefaultLayout';
import { Collapsible } from '@shared/unsorted/Collapsible/Collapsible';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { EventSessionDetail } from './UpcomingEvents/EventSessionDetail';
import { WelcomeModal } from './WelcomeModal/WelcomeModal';
import { useHomePage } from './hook';
import { EventSessionGroupDetail } from './UpcomingEvents/EventSessionGroupDetail';
import { ActivityLogEntry } from '@shared/unsorted/ActivityLogEntry/ActivityLogEntry';

const styles = {
    body: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    hello: Cn.c('font-h2-bold p-6 text-emphasized'),
    container: Cn.c('flex space-x-8 overflow-auto flex-1'),
    column: Cn.c('flex flex-col flex-1 overflow-auto'),
    header: Cn.c('border-b w-full pb-4 border-b-default flex items-center justify-between'),
    title: Cn.c('font-paragraph-base-semi-bold text-emphasized'),
    right: Cn.c('flex items-center space-x-1 font-paragraph-small-medium text-emphasized'),
    icon: Cn.c('w-4 h-4 text-icons-emphasized'),
    emptyContent: Cn.c('flex flex-col items-center pt-16 font-paragraph-base-semi-bold text-disabled'),
    placeHolderIcon: Cn.c('h-16 w-16 text-icons-subdued'),
    emptyDescription: Cn.c('font-paragraph-base-semi-bold text-placeholder pt-2'),
    eventListContainer: Cn.c("flex flex-col flex-1 overflow-auto"),
    eventListItem: Cn.c("pt-4 pb-2 border-b border-b-default"),
    activityListContainer: Cn.c("flex flex-col flex-1 overflow-auto pt-4"),
    activityListItem: Cn.c("mb-6"),
    collapsibleContent: Cn.c("pt-4 px-6"),
    collapsibleListItem: Cn.c("pt-3"),
};

const HomePage = () => {
    const { t } = useTranslation()

    const {
        clientInfo,
        shouldDisplayWelcomeModal,
        onCloseWelcomeModal,
        eventSessions,
        activityLogs
    } = useHomePage();

    return (
        <DefaultLayout>
            <p className={styles.hello}>{t("dashboard.title")}</p>
            <div className={styles.body}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <div className={styles.header}>
                            <p className={styles.title}>{t("dashboard.recentActivity")}</p>
                        </div>
                        {clientInfo?.appConfig?.features?.displayActivityLogs
                            ? (
                                <>
                                    {activityLogs.length > 0 ? (
                                        <ul className={styles.activityListContainer}>
                                            {activityLogs.map(activityLog => (
                                                <li key={activityLog.id} className={styles.activityListItem}>
                                                    <ActivityLogEntry activityLog={activityLog} />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className={styles.emptyContent}>
                                            <Icon name="noNotification" className={styles.placeHolderIcon} />
                                            <p className={styles.emptyDescription}>{t("dashboard.activities.noActivity")}</p>
                                        </div>
                                    )}
                                </>
                            )
                            : (
                                <div className={styles.emptyContent}>
                                    <Icon name="noNotification" className={styles.placeHolderIcon} />
                                    <p className={styles.emptyDescription}>{t("dashboard.comingSoon")}</p>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.column}>
                        <div className={styles.header}>
                            <p className={styles.title}>{t("dashboard.upcomingEvents.title")}</p>
                        </div>
                        {eventSessions.length === 0 ?
                            <div className={styles.emptyContent}>
                                <Icon name="calendar" className={styles.placeHolderIcon} />
                                <p className={styles.emptyDescription}>{t("dashboard.upcomingEvents.noEvent")}</p>
                            </div>
                            : <ul className={styles.eventListContainer}>
                                {eventSessions.map((eventSession) => (
                                    <li key={eventSession.id} className={styles.eventListItem}>
                                        <Collapsible
                                            header={<EventSessionDetail eventSession={eventSession} />}
                                        >
                                            <ul className={styles.collapsibleContent}>
                                                {eventSession.groups.map((group, index) => (
                                                    <li key={group.id} className={Cn.ifTrue(index > 0, styles.collapsibleListItem)}>
                                                        <EventSessionGroupDetail
                                                            eventSessionGroup={group}
                                                            isOnline={eventSession.event.isOnline}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            </div>

            <WelcomeModal isOpen={shouldDisplayWelcomeModal} onCloseModal={onCloseWelcomeModal} />
        </DefaultLayout>
    );
};

export {
    HomePage
};

