import { isOneOf } from '@helpers/core/typeGuards';
import { UserManagement } from '@routes/setting';
import { SortItem } from '@shared/unsorted/Sort/Sort';
import { SelectionOption } from '@typedefs/selectOption';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface UserManagementHookResult {
    sortOptions: SortItem[];
    currentSortId: string;
    onSort: (sortId: string) => void;
    rowsPerPage: number;
    rowsPerPageOptions: SelectionOption[];
    changeRowsPerPage: (rowsPerPage: string) => void;
    currentPage: number;
    changePage: (currentPage: number) => void;
    getPageCount: (totalRows: number) => number;
    searchKeyword?: string | null;
    onSubmitSearch: (keyword: string) => void;
}

const ACCEPTED_STATUS_KEYS = ['REGISTERED', 'INVITED'] as const;
const getStatusKey = (status: string) => isOneOf(ACCEPTED_STATUS_KEYS)(status) ? status : 'REGISTERED';

const useUserManagement = (): UserManagementHookResult => {
    const navigate = useNavigate();

    const queryParams = UserManagement.useSearchParams();

    const onSubmitSearch = (keyword: string) => {
        navigate(UserManagement.toRoute({
            ...queryParams,
            page: 1,
            search: keyword,
            mode: 'list',
        }));
    };

    const sortOptions: SortItem[] = [
        {
            label: 'settings.userManagement.list.sort.nameAsc',
            id: 'NAME asc',
            value: {
                field: 'NAME',
                direction: 'asc',
            },
        },
        {
            label: 'settings.userManagement.list.sort.nameDesc',
            id: 'NAME desc',
            value: {
                field: 'NAME',
                direction: 'desc',
            },
        },
    ];

    const currentSortId = useMemo(() => `${queryParams.sortType} ${queryParams.order}`, [queryParams.order, queryParams.sortType]);

    const onSort = (sortId: string) => {
        const sortItem = sortOptions.find(sortItem => sortItem.id === sortId) || sortOptions[0]

        if (isOneOf(UserManagement.supportedSort)(sortItem.value.field)) {
            navigate(UserManagement.toRoute({
                ...queryParams,
                sortType: sortItem.value.field,
                order: sortItem.value.direction,
                mode: 'list',
            }));
        }
    };

    const rowsPerPageOptions: SelectionOption[] = [
        {
            label: '10',
            value: '10',
            key: '10',
        },
        {
            label: '25',
            value: '25',
            key: '25',
        },
        {
            label: '50',
            value: '50',
            key: '50',
        },
        {
            label: '75',
            value: '75',
            key: '75',
        },
        {
            label: '100',
            value: '100',
            key: '100',
        },
    ];

    const changeRowsPerPage = (rowsPerPage: string) => {
        navigate(UserManagement.toRoute({
            ...queryParams,
            page: 1,
            rowsPerPage: parseInt(rowsPerPage),
            mode: 'list',
        }))
    };

    const changePage = (currentPage: number) => {
        navigate(UserManagement.toRoute({
            ...queryParams,
            page: currentPage,
            mode: 'list',
        }))
    };

    return {
        sortOptions,
        currentSortId,
        onSort,
        rowsPerPage: queryParams.rowsPerPage,
        rowsPerPageOptions,
        changeRowsPerPage,
        currentPage: queryParams.page,
        changePage,
        getPageCount: (totalRows: number) => Math.ceil(totalRows / queryParams.rowsPerPage),
        searchKeyword: queryParams.search,
        onSubmitSearch,
    };
};

export {
    getStatusKey,
    useUserManagement
};

