import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useEffect } from 'react';
import { InitialState } from './init';

const usePreferences = (initialState: InitialState) => {
    const { error: toastError } = useToast();

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])
};

export {
    usePreferences
};
