import { Applications_AllApplicationDocument, AssignApplicationsToEventSessionGroupDocument, EventSession_OneByIdQuery } from '@entities';
import { hasValue } from '@helpers/core/typeGuards';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse, useQueryContext } from '@helpers/unsorted/urqlExtra';
import { Entity } from '@typedefs/graphql';
import { SelectionOption } from '@typedefs/selectOption';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { I18nKey } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import { ValueItem as MultiValueTypeAheadItem } from '@shared/unsorted/MultiValueTypeAhead/value';
import { SubMode as EventSessionGroupManipulateCandidateSubMode } from "../EventSessionGroupManipulateCandidateModal/hook";
import * as value from './value';
import { SimpleApplication } from '@typedefs/aliases';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';

interface EventSessionGroupAddCandidateModalHookType {
    isSubmitting: boolean;
    onCloseModal: VoidFunction;
    form: UseFormReturn<value.Encoder.EncoderType>;
    applicationOptions: SelectionOption[];
    filterMatchingCandidates: (currentApplicationOptions: MultiValueTypeAheadItem.ValueItemType[], input?: string) => void;
    filterSelectedApplications: (currentApplicationOptions: MultiValueTypeAheadItem.ValueItemType[]) => void;
    selectedApplications: MultiValueTypeAheadItem.ValueItemType[];
    onSubmit: FormEventHandler<HTMLFormElement>;
    numOfAssignedCandidates: number;
    onValidateAllocatedCandidates: () => I18nKey | undefined;
}

const useEventSessionGroupAddCandidateModal = (
    closeModal: VoidFunction,
    recruitmentProcessAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.recruitmentProcessAssignments'>[],
    subMode: EventSessionGroupManipulateCandidateSubMode,
): EventSessionGroupAddCandidateModalHookType => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [candidateName, setCandidateName] = useState('');

    const [selectableApplications, setSelectableApplications] = useState<SimpleApplication[]>([]);

    const { error: toastError, success: toastSuccess } = useToast();

    const [updateGroupCandidatesResponse, updateGroupCandidates] = useMutation(AssignApplicationsToEventSessionGroupDocument);

    const { event } = useEventSessionDetailContext();

    const applicationsQueryContext = useQueryContext(['RecruitmentProcesses']);
    const [applicationsResponse] = useQuery({
        query: Applications_AllApplicationDocument,
        variables: {
            input: {
                jobPositionIds: [event.recruitmentStepV2.jobPosition.id],
                stepIds: [event.recruitmentStepV2.id],
                stepStatuses: ['SCHEDULE_ADJUSTMENT'],
                candidateName,
            }
        },
        context: applicationsQueryContext,
        pause: subMode.name !== 'add',
    });

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues: value.Encoder.defaultValues(),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const { control, reset, handleSubmit } = form;

    const selectedApplications = useWatch({
        control,
        name: 'recruitmentProcessIds',
    });

    const numOfAssignedCandidates = selectedApplications.length + recruitmentProcessAssignments.length;

    const allApplications = useRef<SimpleApplication[]>([]);

    const currentSelectedApplications = useRef<MultiValueTypeAheadItem.ValueItemType[]>([]);

    const initApplications: MultiValueTypeAheadItem.ValueItemType[] = recruitmentProcessAssignments.map(({ recruitmentProcess }) => ({
        value: recruitmentProcess.id,
        label: recruitmentProcess.candidate.name,
    }));

    const getRemainingApplications = (currentApplicationOptions: MultiValueTypeAheadItem.ValueItemType[]) => {
        const currentCandidateIds = currentApplicationOptions.concat(initApplications).map(({ value }) => value);
        const isFound = (id: string) => currentCandidateIds.includes(id);
        const remainingApplications = allApplications.current.filter(application => !isFound(application.id));

        return remainingApplications;
    };

    const filterSelectedApplications = (currentApplicationOptions: MultiValueTypeAheadItem.ValueItemType[]) => {
        const remainingApplications = getRemainingApplications(currentApplicationOptions);

        setSelectableApplications(remainingApplications);
    };

    const getApplicationOptions = (applications: SimpleApplication[]): SelectionOption[] =>
        applications.map(application => ({
            key: application.id,
            label: `${application.candidate.name} (${application.candidate.email})`,
            value: application.id,
        }));

    const applicationOptions = getApplicationOptions(selectableApplications);

    const getComparableString = (value: string) => value.trim().split(' ').join('').toLowerCase();

    const filterMatchingCandidates = (currentApplicationOptions: MultiValueTypeAheadItem.ValueItemType[], input?: string) => {
        if (input !== undefined) {
            const cleanedCandidateName = getComparableString(input);
            currentSelectedApplications.current = currentApplicationOptions;

            setCandidateName(cleanedCandidateName);
        }
    };

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            if (subMode.name === 'add') {
                updateGroupCandidates({
                    groupId: subMode.payload.groupId,
                    stepId: subMode.payload.stepId,
                    applicationIds: validValues.recruitmentProcessIds,
                }, getQueryContext(['EventSessionGroup', 'RecruitmentProcesses', 'RecruitmentProcess']));
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    const close = () => {
        currentSelectedApplications.current = [];
        reset();
        closeModal();
    };

    const onValidateAllocatedCandidates = (): I18nKey | undefined => {
        if (numOfAssignedCandidates > event.candidateCapacity) {

            return 'event.eventSessionGroupAddCandidateModal.warning';
        }
    };

    useEffect(() => {
        handleResponse(applicationsResponse, {
            onData: ({ applications: { edges } }) => {
                allApplications.current = edges;

                filterSelectedApplications(currentSelectedApplications.current);
            },
            onError: () => {
                toastError('global.error');
                setSelectableApplications(_ => []);
            }
        });
    }, [applicationsResponse]);

    useEffect(() => {
        handleResponse(updateGroupCandidatesResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false);

                if (data.assignApplicationsToEventSessionGroup.success === selectedApplications.length) {
                    close();
                    toastSuccess('event.eventSessionGroupAddCandidateModal.success');
                } else {
                    toastError('event.eventSessionGroupAddCandidateModal.error');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('event.eventSessionGroupAddCandidateModal.error');
            }
        });
    }, [updateGroupCandidatesResponse]);

    return {
        isSubmitting,
        onCloseModal: close,
        form,
        applicationOptions,
        filterMatchingCandidates,
        filterSelectedApplications,
        selectedApplications,
        onSubmit: handleSubmit(onSubmit),
        numOfAssignedCandidates,
        onValidateAllocatedCandidates,
    };
};

export {
    useEventSessionGroupAddCandidateModal
};
