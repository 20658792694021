import { JobPosition_OneByIdWithStepEventsQuery, SelectableStepTypesQuery } from "@entities";
import { Cn } from '@helpers/unsorted/classNames';
import { RecruitmentFlowSubForm } from '@shared/job_position/RecruitmentFlow/RecruitmentFlowSubForm/RecruitmentFlowSubForm';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { TextArea } from '@shared/unsorted/TextArea/TextArea';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useJobPositionForm } from './hook';
import { Input } from "@shared/unsorted/Input/Input";
import { WarningModal } from "../../shared/WarningModal";
import { OpenJobListPanelButton } from "@shared/job_position/JobListPanel/OpenJobListPanelButton";
import { InitialStateData } from "../init";

const styles = {
    container: Cn.c('flex flex-col bg-surface-default h-full'),
    header: Cn.c('px-6 py-4 flex items-center justify-between'),
    rightContainer: Cn.c('flex items-center justify-between space-x-5'),
    buttons: Cn.c('flex items-center justify-between space-x-4'),
    modalTitle: Cn.c('font-paragraph-base-medium text-emphasized'),
    closeIcon: Cn.c('w-6 h-6 text-icons-emphasized cursor-pointer'),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
    loadingContainer: Cn.c('flex items-center justify-center flex-1'),
    section: Cn.c('pb-8'),
    sectionTitle: Cn.c('font-h2-bold text-emphasized mb-2'),
    description: Cn.c('font-paragraph-base-regular text-subdued mb-4'),
    textArea: Cn.c('h-24'),
    jobListContainer: Cn.c('flex content-center items-center space-x-4'),
    overview: Cn.c('pt-2 space-y-6'),
}

interface Props extends InitialStateData {
    jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>
    switchToViewMode: VoidFunction
}

const JobPositionDetailEditModeSuccessState: FunctionComponent<Props> = ({
    jobPosition,
    recruitmentStepTypes,
    switchToViewMode,
}) => {
    const { t } = useTranslation();

    const {
        form,
        onSubmit,
        isSubmitting,
        onCancel,
        onClose,
        showWarningModal,
        discardAndClose,
        saveAndExit,
        closeConfirmationModal,
    } = useJobPositionForm(jobPosition, switchToViewMode);

    const { control, clearErrors, formState: { errors, isDirty } } = form;

    return (
        <>
            <form className={styles.container} onSubmit={onSubmit}>
                <div className={styles.header}>
                    <div className={styles.jobListContainer}>
                        <OpenJobListPanelButton />
                        <p className={styles.modalTitle}>{
                            jobPosition.title
                        }</p>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.buttons}>
                            <Button
                                size="md"
                                variant="secondary"
                                onClick={onCancel}
                                isDisabled={isSubmitting}
                            >
                                {t('global.cancel')}
                            </Button>
                            <Button
                                size="md"
                                variant="primaryFilled"
                                type="submit"
                                isDisabled={isSubmitting || !isDirty}
                                isLoading={isSubmitting}
                            >
                                {t('global.save')}
                            </Button>
                        </div>
                        <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>{t("jobPositions.panel.overview.title")}</div>
                        <div className={styles.description}>{t("jobPositions.panel.overview.description")}</div>
                        <div className={styles.overview}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                    <Input
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        errors={errors}
                                        value={value}
                                        label="jobPositions.panel.jobTitle"
                                        required
                                    />}
                            />
                            <Controller
                                name="documentRequirements"
                                control={control}
                                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                    <TextArea
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        errors={errors}
                                        value={value}
                                        textAreaClassName={styles.textArea}
                                        placeholder="jobPositions.shared.placeholder.documentRequirement"
                                        label="jobPositions.shared.documentRequirement.title"
                                        orientation="vertical"
                                    />}
                            />
                        </div>
                    </div>
                    <div className={styles.section}>
                        <p className={styles.sectionTitle}>{t("jobPositions.shared.recruitmentFlow.title")}</p>
                        <p className={styles.description}>{t("jobPositions.modify.recruitmentFlowDescription")}</p>
                        <FormProvider {...form}>
                            <RecruitmentFlowSubForm recruitmentStepTypes={recruitmentStepTypes} isEditMode />
                        </FormProvider>
                    </div>
                </div>
            </form >
            <WarningModal
                isOpen={showWarningModal}
                onClose={closeConfirmationModal}
                onDiscard={discardAndClose}
                onSaveAndExit={saveAndExit}
            />
        </>
    );
}

export {
    JobPositionDetailEditModeSuccessState
};
