import { Candidate, CandidateAdd } from "@routes/candidate";
import { useNavigate } from "react-router-dom";

const useCSVImportPage = () => {
    const navigate = useNavigate();

    const switchToManuallyAdding = () => {
        navigate(CandidateAdd.MANUAL_PATH_NAME);
    }

    const switchToTop = () => {
        navigate(Candidate.PATH_NAME)
    }

    return {
        switchToManuallyAdding,
        switchToTop,
    }
}

export {
    useCSVImportPage
};
