import { ApplicationsPage } from '@pages/ApplicationsPage/ApplicationsPage';
import { EventRegistrationPage } from '@pages/EventRegistration/EventRegistration';
import { HomePage } from '@pages/HomePage/HomePage';
import { JobPositionCreatePage } from '@pages/JobPositionCreatePage/JobPositionCreatePage';
import { JobPositionsPage } from '@pages/JobPositionsPage/JobPositionsPage';
import { LoginDevPage } from '@pages/LoginDevPage/LoginDevPage';
import { NotFoundPage } from '@pages/NotFoundPage/NotFoundPage';
import { SettingsPage } from '@pages/SettingsPage/SettingsPage';
import * as Sentry from '@sentry/react';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import config from '@helpers/core/config';
import { AnalyticsPage } from '@pages/AnalyticsPage/AnalyticsPage';
import { DocumentUploadPage } from '@pages/DocumentUpload/DocumentUploadPage';
import { ThrowErrorPage } from '@pages/ThrowErrorPage/ThrowErrorPage';
import { useEffect } from 'react';
import { CSVImportPage } from '@pages/application/CSVImportPage/CSVImportPage';
import { ManualAddPage } from '@pages/application/ManualAddPage/ManualAddPage';
import { JobPosition, JobPositionCreate, JobPositionDetail } from '@routes/jobPosition';
import { JobPositionDetailPage } from '@pages/JobPositionDetailPage/JobPositionDetailPage';
import { EventDetail } from '@routes/event';
import { EventDetailPage } from '@pages/EventDetailPage/EventDetailPage';
import { EventSessionDetail } from '@routes/eventSession';
import { EventSessionDetailPage } from '@pages/EventSessionDetailPage/EventSessionDetailPage';
import { Candidate, CandidateAdd } from '@routes/candidate';
import { Account, Preferences, Settings, UserManagement } from '@routes/setting';
import { DocumentUpload, EventRegistration } from '@routes/unauthenticated';
import { Home } from '@routes/home';
import { Analytics } from '@routes/analytics';
import { useClient } from '@helpers/hooks/unsorted/clientHook';

Sentry.init({
  dsn: config.sentryDSN,
  debug: config.environment !== 'prod',
  environment: config.environment,
  enabled: config.environment !== 'dev',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: config.environment === 'stage'
    ? 1.0
    : config.environment === 'prod' ? 0.1 : 0, // Capture 100% of the transactions in staging, reduce in production to 0.1, disable in dev!
  tracePropagationTargets: [config.graphqlEndpoint],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This seems to send a lot of data to Sentry, better keep it low
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutesFromElements((
  <>
    <Route path={Home.PATH_NAME} element={<HomePage />} />
    <Route path={JobPosition.PATH_NAME}>
      <Route index element={<JobPositionsPage />} />
      <Route path={JobPositionCreate.PATH_NAME} element={<JobPositionCreatePage />} />
      <Route path={JobPositionDetail.PATH_NAME} element={<JobPositionDetailPage />} />
    </Route>
    <Route path={EventDetail.PATH_NAME} element={<EventDetailPage />} />
    <Route path={EventSessionDetail.PATH_NAME} element={<EventSessionDetailPage />} />
    <Route path={Candidate.PATH_NAME}>
      <Route index element={<ApplicationsPage defaultTab='active' />} />
      <Route path={CandidateAdd.CSV_IMPORT_PATH_NAME} element={<CSVImportPage />} />
      <Route path={CandidateAdd.MANUAL_PATH_NAME} element={<ManualAddPage />} />
    </Route>
    <Route path={Settings.PATH_NAME}>
      <Route index element={<Navigate replace to={Account.PATH_NAME} />} />
      <Route path={UserManagement.PATH_NAME} element={<SettingsPage defaultTab={"users"} />} />
      <Route path={Account.PATH_NAME} element={<SettingsPage defaultTab={"account"} />} />
      <Route path={Preferences.PATH_NAME} element={<SettingsPage defaultTab={"preferences"} />} />
      {/* <Route path={Templates.pathName} element={<SettingsPage defaultTab={"templates"} />} /> */}
    </Route>
    <Route path={EventRegistration.PATH_NAME} element={<EventRegistrationPage />} />
    <Route path={DocumentUpload.PATH_NAME} element={<DocumentUploadPage />} />
    {/* <Route path={Calendar.pathName} element={<CalendarPage />} /> */}
    <Route path={Analytics.PATH_NAME} element={<AnalyticsPage />} />
    <Route path='/dev/login' element={<LoginDevPage />} />
    <Route path="*" element={<NotFoundPage />} />
    <Route path='/error' element={config.environment === 'prod' ? null : <ThrowErrorPage />} /> {/* TODO: remove when completing setting up Sentry */}
  </>
)));

const App = () => {
  useClient()
  return <RouterProvider router={router} />;
};

export { App };
