import { allClosedStepStatuses, allOngoingStepStatuses, allRecruitmentStages, ClosedStepStatus, OngoingStepStatus } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { RecruitmentStageType } from "@typedefs/aliases";
import { useMemo } from "react";
import { toQueryString, useRouteQuery } from "./hook";

export namespace Candidate {
    export const PATH_NAME = "/candidates";

    interface ListMode {
        name: "list";
    }

    interface ShowMode {
        name: "show";
        payload: {
            applicationId: string;
        };
    }

    interface CreateMode {
        name: "create";
    }

    export type Mode = ListMode | ShowMode | CreateMode;

    export const supportedSort = ["UPDATED_AT", "CREATED_AT", "CANDIDATE_NAME"] as const

    export type SortType = typeof supportedSort[number]

    const order = ["asc", "desc"] as const

    export type Order = typeof order[number]

    export const applicationTypes = ["ONGOING", "ARCHIVED", "ALL"] as const

    export type ApplicationType = typeof applicationTypes[number]

    type Search = {
        candidateName?: string;
    };

    export interface QueryParams {
        page: number;
        mode: Mode;
        jobPositionIds: string[];
        stepIds?: string[]
        schoolIds?: string[]
        search: Search;
        rowsPerPage: number,
        sortType: SortType;
        order: Order;
        applicationType?: ApplicationType
        stages?: RecruitmentStageType[]
        ongoingStepStatuses?: OngoingStepStatus[]
        archivedStepStatuses?: ClosedStepStatus[]
    }

    const resolveMode = (searchParams: URLSearchParams): Mode => {
        const pageMode = searchParams.get("mode");

        const applicationId = searchParams.get("id");

        switch (pageMode) {
            case "create":
                return { name: "create" };

            case "show":
                return applicationId
                    ? {
                        name: 'show',
                        payload: {
                            applicationId,
                        }
                    }
                    : { name: 'list' };


            default:
                return { name: "list" };
        }
    };

    export const useSearchParams = (): QueryParams => {
        const searchParams = useRouteQuery();
        const mode = useMemo(() => resolveMode(searchParams), [searchParams]);

        const pageNumber = searchParams.get("page") ?? "1";
        const jobPositionIdsString = searchParams.get("job_position_ids");
        const search = { candidateName: searchParams.get("search") || "" };
        const stagesString = searchParams.get("stages")
        const schoolIdsString = searchParams.get("school_ids")
        const ongoingStepStatusesString = searchParams.get("ongoing_step_statuses")
        const archivedStepStatusesString = searchParams.get("archived_step_statuses")
        const stepIdsString = searchParams.get("step_ids")
        const sortType = searchParams.get("sort_type")
        const sortOrder = searchParams.get("order")
        const applicationType = searchParams.get("application_type")
        const jobPositionIds = useMemo(() => jobPositionIdsString?.length ? jobPositionIdsString.split(",") : [], [jobPositionIdsString])
        const stages = useMemo(() => stagesString?.length ? stagesString.split(",").filter(isOneOf(allRecruitmentStages)) : [], [stagesString])
        const ongoingStepStatuses = useMemo(() => ongoingStepStatusesString?.length ? ongoingStepStatusesString.split(",").filter(isOneOf(allOngoingStepStatuses)) : [], [ongoingStepStatusesString])
        const archivedStepStatuses = useMemo(() => archivedStepStatusesString?.length ? archivedStepStatusesString.split(",").filter(isOneOf(allClosedStepStatuses)) : [], [archivedStepStatusesString])
        const stepIds = useMemo(() => stepIdsString?.length ? stepIdsString.split(",") : [], [stepIdsString])
        const schoolIds = useMemo(() => schoolIdsString?.length ? schoolIdsString.split(",") : [], [schoolIdsString])

        return {
            page: parseInt(pageNumber, 10) || 1,
            rowsPerPage: parseInt(searchParams.get('rows_per_page') ?? '25'),
            mode,
            jobPositionIds,
            schoolIds,
            stepIds,
            search,
            sortType: isOneOf(supportedSort)(sortType) ? sortType : "CREATED_AT",
            order: isOneOf(order)(sortOrder) ? sortOrder : "desc",
            applicationType: isOneOf(applicationTypes)(applicationType) ? applicationType : undefined,
            stages,
            ongoingStepStatuses,
            archivedStepStatuses,
        };
    };

    export const toRoute = ({
        page = 1,
        search,
        mode = "list",
        jobPositionIds = [],
        sortType = "CREATED_AT",
        order = "desc",
        rowsPerPage = 25,
        applicationId,
        stepIds = [],
        applicationType,
        stages,
        ongoingStepStatuses,
        archivedStepStatuses,
        schoolIds,
    }: {
        page?: number;
        rowsPerPage?: number,
        stepName?: string;
        search?: Search;
        mode?: Mode["name"];
        jobPositionIds?: string[];
        sortType?: SortType;
        order?: Order;
        applicationId?: string;
        stepIds?: string[];
        applicationType?: ApplicationType
        stages?: RecruitmentStageType[]
        ongoingStepStatuses?: OngoingStepStatus[]
        archivedStepStatuses?: ClosedStepStatus[]
        schoolIds?: string[]
    } = {}) => {
        const searchQuery = {
            page: page.toString(),
            rows_per_page: rowsPerPage.toString(),
            mode,
            job_position_ids: jobPositionIds.length
                ? jobPositionIds.join(",")
                : undefined,
            sort_type: sortType,
            order,
            search: search?.candidateName,
            id: applicationId,
            step_ids: stepIds.length ? stepIds.join(",") : undefined,
            application_type: isOneOf(applicationTypes)(applicationType) ? applicationType : undefined,
            stages: stages?.join(","),
            ongoing_step_statuses: ongoingStepStatuses?.join(","),
            archived_step_statuses: archivedStepStatuses?.join(","),
            school_ids: schoolIds?.join(","),
        };

        return {
            PATH_NAME,
            search: `?${toQueryString(searchQuery)}`,
        };
    };
}

export namespace CandidateAdd {
    export const CSV_IMPORT_PATH_NAME = `${Candidate.PATH_NAME}/add/csv_import`;

    export const MANUAL_PATH_NAME = `${Candidate.PATH_NAME}/add/manual`;
}