import { useTranslation } from 'react-i18next';

import { RoleName } from '@entities';

interface ReadOnlyUserInfoModalHookResult {
    tooltips: Record<RoleName, string>;
}

const useReadOnlyUserInfoModal = (): ReadOnlyUserInfoModalHookResult => {
    const { t } = useTranslation();

    const tooltips: Record<RoleName, string> = {
        'ScovilleAdmin': t('users.create.tooltips.scovilleAdmin'),
        'Scoville': t('users.create.tooltips.scoville'),
        'Admin': t('users.create.tooltips.admin'),
        'Coordinator': t('users.create.tooltips.coordinator'),
        'Examiner': t('users.create.tooltips.examiner'),
    };

    return { tooltips };
};

export {
    useReadOnlyUserInfoModal,
};