
import { Button } from '@shared/unsorted/Button/Button';
import { DefaultLayout } from '@shared/unsorted/DefaultLayout/DefaultLayout';
import { EmptyPageContainer } from '@shared/unsorted/EmptyStateContainer/EmptyPageContainer';
import { useTranslation } from 'react-i18next';

const AnalyticsPage = () => {
    const { t } = useTranslation();

    // TODO: Temporarily declared this here since there's no point of creating a new hook file. Move to hook file if needed.
    const openEmail = () => {
        window.location.href = `mailto:bluum@reccoo.com`
    };

    return (
        <DefaultLayout>
            <EmptyPageContainer
                icon="emptyReport"
                description={t("comingSoon.description", { feature: t("analytics.title") })}
                subDescription="comingSoon.subDescription"
            >
                <Button size="md" onClick={openEmail}>{t("comingSoon.contactUs")}</Button>
            </EmptyPageContainer>
        </DefaultLayout>
    );
};

export {
    AnalyticsPage
};
