import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Mode, useArchiveActions } from './hook';
import { allClosedStepStatuses } from '@helpers/core/constants';

type Direction = 'upward' | 'downward';

const styles = {
    button: Cn.c('ml-px flex-1'),
    caretIcon: (mode: Mode) => Cn.join([
        Cn.c('ml-2 w-6 h-6'),
        Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180'))
    ]),
    list: (direction: Direction) => Cn.join([
        Cn.c('w-full absolute z-10 bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md'),
        direction === 'upward' ? Cn.c('bottom-16') : Cn.c('right-0 top-12'),
    ]),
    listRow: Cn.c('block cursor-pointer font-paragraph-small-regular px-4 py-2 hover:bg-surface-hovered-default'),
    actionsContent: Cn.c('flex flex-row items-center justify-around'),
};

// @ocaml.doc("Button to display archive actions on recruitment process
//     - `direction`[#upwards | #downwards] - absolute positioning of the list, whether to open upwards or downwards
// ")
interface Props {
    applicationId: string;
    direction?: Direction;
}

const ArchiveActions: FunctionComponent<Props> = ({
    applicationId,
    direction = 'downward',
}) => {
    const { t } = useTranslation();

    const {
        actionListDisplayMode,
        toggleArchiveActionList,
        archiveActionsRef,
        onOptionClick,
    } = useArchiveActions();

    return (
        <div ref={archiveActionsRef}>
            <Button
                size="md"
                variant="secondary"
                onClick={toggleArchiveActionList}
                className={styles.button}
            >
                <div className={styles.actionsContent}>
                    {t("applications.panel.recruitmentStepArchiveActions")}
                    <Icon name="caret" className={styles.caretIcon(actionListDisplayMode)} />
                </div>
            </Button>
            {actionListDisplayMode === "show" &&
                <div className={styles.list(direction)}>
                    {allClosedStepStatuses.map((closedStepStatus, index) =>
                        <div className={styles.listRow} key={index} onClick={() => onOptionClick(closedStepStatus, applicationId)}>
                            {t(`recruitmentStepStatusV2.${closedStepStatus}`)}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export {
    ArchiveActions,
};
