import { z } from "zod"
import { check } from "./zod";

export const activityActionTypeSchema = z.enum([
    "INVITE_USER",
    "DELETE_USER",
    "EDIT_PREFERENCES",
    "ADD_APPLICATION_MANUALLY",
    "ADD_APPLICATION_CSV",
    "EDIT_CANDIDATE_INFO",
    "UPLOAD_CANDIDATE_DOCUMENT",
    "DELETE_CANDIDATE_DOCUMENT",
    "SEND_CANDIDATE_EMAIL",
    "RECEIVE_CANDIDATE_EMAIL",
    "UPDATE_APPLICATION_STATUS",
    "ARCHIVE_APPLICATION",
    "UNARCHIVE_APPLICATION",
    "DELETE_CANDIDATE",
    "FILL_EVALUATION",
    "ADD_JOB",
    "DELETE_JOB",
    "EDIT_JOB",
    "SET_EVALUATION_FORM",
    "EDIT_EMAIL_TEMPLATE",
    "SET_EVENT",
    "EDIT_EVENT",
    "ADD_EVENT_SESSION",
    "EDIT_EVENT_SESSION",
    "ASSIGN_CANDIDATE",
    "UNASSIGN_CANDIDATE",
    "ASSIGN_EXAMINER",
    "UNASSIGN_EXAMINER",
])

export type ActivityActionType = z.infer<typeof activityActionTypeSchema>

export const activityTargetTypeSchema = z.enum([
    "USER",
    "SETTINGS",
    "APPLICATION",
    "CANDIDATE",
    "JOB",
    "STEP",
    "EVENT",
])

export type ActivityTargetType = z.infer<typeof activityTargetTypeSchema>

export const activityActorTypeSchema = z.enum(["USER", "SYSTEM"])

export type ActivityActorType = z.infer<typeof activityActorTypeSchema>

export const baseActivityLogSchema = z.object({
    id: z.string(),
    actionType: activityActionTypeSchema,
    actionTimestamp: z.date(),
    isBulk: z.boolean(),
    targetId: z.string().nullish(),
    targetType: activityTargetTypeSchema,
    actorId: z.string().nullish(),
    actorType: activityActorTypeSchema,
    properties: z.unknown(),
});

export type BaseActivityLog = z.infer<typeof baseActivityLogSchema>;

// https://docs.google.com/spreadsheets/d/1M5sHRIIGEwwRvt959Bak_7LMVHbBNNz4NDM91CZBiLw/edit?gid=8315383#gid=8315383

export const inviteUserUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.INVITE_USER),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            invitedUserName: z.string(),
            invitedUserEmail: z.string().email(),
        }),
    })

export type InviteUserUserActivityLog = z.infer<
    typeof inviteUserUserActivityLogSchema
>

export const deleteUserUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.DELETE_USER),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            deletedUserName: z.string(),
        }),
    })

export type DeleteUserUserActivityLog = z.infer<
    typeof deleteUserUserActivityLogSchema
>

export const editPreferencesUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_PREFERENCES),
        isBulk: z.literal(false),
        targetType: z.literal(activityTargetTypeSchema.Enum.SETTINGS),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            fieldName: z.string(),
        }),
    })

export type EditPreferencesUserActivityLog = z.infer<
    typeof editPreferencesUserActivityLogSchema
>

export const addApplicationManualUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.ADD_APPLICATION_MANUALLY,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            jobName: z.string(),
        }),
    })

export type AddApplicationManualUserActivityLog = z.infer<
    typeof addApplicationManualUserActivityLogSchema
>

export const addApplicationCsvUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ADD_APPLICATION_CSV),
        isBulk: z.literal(false),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
            jobName: z.string(),
        }),
    })

export type AddApplicationCsvUserActivityLog = z.infer<
    typeof addApplicationCsvUserActivityLogSchema
>

export const editCandidateInfoUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_CANDIDATE_INFO),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type EditCandidateInfoUserActivityLog = z.infer<
    typeof editCandidateInfoUserActivityLogSchema
>

export const uploadCandidateDocumentUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.UPLOAD_CANDIDATE_DOCUMENT,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            fileName: z.string(),
        }),
    })

export type UploadCandidateDocumentUserActivityLog = z.infer<
    typeof uploadCandidateDocumentUserActivityLogSchema
>

export const deleteCandidateDocumentUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.DELETE_CANDIDATE_DOCUMENT,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            invitedUserName: z.string(),
            invitedUserEmail: z.string().email(),
        }),
    })

export type DeleteCandidateDocumentUserActivityLog = z.infer<
    typeof deleteCandidateDocumentUserActivityLogSchema
>

export const sendCandidateEmailUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.SEND_CANDIDATE_EMAIL),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type SendCandidateEmailUserActivityLog = z.infer<
    typeof sendCandidateEmailUserActivityLogSchema
>

export const sendCandidateEmailBulkUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.SEND_CANDIDATE_EMAIL),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
        }),
    })

export type SendCandidateEmailBulkUserActivityLog = z.infer<
    typeof sendCandidateEmailBulkUserActivityLogSchema
>

export const sendCandidateEmailSystemActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.SEND_CANDIDATE_EMAIL),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorType: z.literal(activityActorTypeSchema.Enum.SYSTEM),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type SendCandidateEmailSystemActivityLog = z.infer<
    typeof sendCandidateEmailSystemActivityLogSchema
>

export const receiveCandidateEmailSystemActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.RECEIVE_CANDIDATE_EMAIL,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorType: z.literal(activityActorTypeSchema.Enum.SYSTEM),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type ReceiveCandidateEmailSystemActivityLog = z.infer<
    typeof receiveCandidateEmailSystemActivityLogSchema
>

export const updateApplicationStatusUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.UPDATE_APPLICATION_STATUS,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type UpdateApplicationStatusUserActivityLog = z.infer<
    typeof updateApplicationStatusUserActivityLogSchema
>

export const updateApplicationStatusBulkUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.UPDATE_APPLICATION_STATUS,
        ),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
        }),
    })

export type UpdateApplicationStatusBulkUserActivityLog = z.infer<
    typeof updateApplicationStatusBulkUserActivityLogSchema
>

export const updateApplicationStatusSystemActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.UPDATE_APPLICATION_STATUS,
        ),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorType: z.literal(activityActorTypeSchema.Enum.SYSTEM),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type UpdateApplicationStatusSystemActivityLog = z.infer<
    typeof updateApplicationStatusSystemActivityLogSchema
>

export const updateApplicationStatusBulkSystemActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(
            activityActionTypeSchema.Enum.UPDATE_APPLICATION_STATUS,
        ),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorType: z.literal(activityActorTypeSchema.Enum.SYSTEM),
        properties: z.object({
            candidateCount: z.number(),
        }),
    })

export type UpdateApplicationStatusBulkSystemActivityLog = z.infer<
    typeof updateApplicationStatusBulkSystemActivityLogSchema
>

export const archiveApplicationUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ARCHIVE_APPLICATION),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            archiveReason: z.string(),
        }),
    })

export type ArchiveApplicationUserActivityLog = z.infer<
    typeof archiveApplicationUserActivityLogSchema
>

export const archiveApplicationUserBulkActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ARCHIVE_APPLICATION),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
            archiveReason: z.string(),
        }),
    })

export type ArchiveApplicationUserBulkActivityLog = z.infer<
    typeof archiveApplicationUserBulkActivityLogSchema
>

export const unarchiveApplicationUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.UNARCHIVE_APPLICATION),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type UnarchiveApplicationUserActivityLog = z.infer<
    typeof unarchiveApplicationUserActivityLogSchema
>

export const unarchiveApplicationUserBulkActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.UNARCHIVE_APPLICATION),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
        }),
    })

export type UnarchiveApplicationUserBulkActivityLog = z.infer<
    typeof unarchiveApplicationUserBulkActivityLogSchema
>

export const deleteCandidateUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.DELETE_CANDIDATE),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
        }),
    })

export type DeleteCandidateUserActivityLog = z.infer<
    typeof deleteCandidateUserActivityLogSchema
>

export const deleteCandidateUserBulkActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.DELETE_CANDIDATE),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.CANDIDATE),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
        }),
    })

export type DeleteCandidateUserBulkActivityLog = z.infer<
    typeof deleteCandidateUserBulkActivityLogSchema
>

export const fillEvaluationUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.FILL_EVALUATION),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type FillEvaluationUserActivityLog = z.infer<
    typeof fillEvaluationUserActivityLogSchema
>

export const fillEvaluationUserBulkActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.FILL_EVALUATION),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type FillEvaluationUserBulkActivityLog = z.infer<
    typeof fillEvaluationUserBulkActivityLogSchema
>

export const addJobUserActivityLogSchema = baseActivityLogSchema.extend(
    {
        actionType: z.literal(activityActionTypeSchema.Enum.ADD_JOB),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.JOB),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
        }),
    },
)

export type AddJobUserActivityLog = z.infer<
    typeof addJobUserActivityLogSchema
>

export const deleteJobUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.DELETE_JOB),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.JOB),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
        }),
    })

export type DeleteJobUserActivityLog = z.infer<
    typeof deleteJobUserActivityLogSchema
>

export const editJobUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_JOB),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.JOB),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
        }),
    })

export type EditJobUserActivityLog = z.infer<
    typeof editJobUserActivityLogSchema
>

export const setEvaluationFormUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.SET_EVALUATION_FORM),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.STEP),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type SetEvaluationFormUserActivityLog = z.infer<
    typeof setEvaluationFormUserActivityLogSchema
>

export const editEmailTemplateUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_EMAIL_TEMPLATE),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.STEP),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type EditEmailTemplateUserActivityLog = z.infer<
    typeof editEmailTemplateUserActivityLogSchema
>

export const setEventUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.SET_EVENT),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.STEP),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type SetEventUserActivityLog = z.infer<
    typeof setEventUserActivityLogSchema
>

export const editEventUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_EVENT),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.EVENT),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
        }),
    })

export type EditEventUserActivityLog = z.infer<
    typeof editEventUserActivityLogSchema
>

export const addEventSessionUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ADD_EVENT_SESSION),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.EVENT),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
        }),
    })

export type AddEventSessionUserActivityLog = z.infer<
    typeof addEventSessionUserActivityLogSchema
>

export const editEventSessionUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.EDIT_EVENT_SESSION),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.EVENT),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            jobName: z.string(),
            stepName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
        }),
    })

export type EditEventSessionUserActivityLog = z.infer<
    typeof editEventSessionUserActivityLogSchema
>

export const assignCandidateUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ASSIGN_CANDIDATE),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
            groupNumber: z.string(),
        }),
    })

export type AssignCandidateUserActivityLog = z.infer<
    typeof assignCandidateUserActivityLogSchema
>

export const assignCandidateBulkUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ASSIGN_CANDIDATE),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
            groupNumber: z.string(),
        }),
    })

export type AssignCandidateBulkUserActivityLog = z.infer<
    typeof assignCandidateBulkUserActivityLogSchema
>

export const unassignCandidateUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.UNASSIGN_CANDIDATE),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
        }),
    })

export type UnassignCandidateUserActivityLog = z.infer<
    typeof unassignCandidateUserActivityLogSchema
>

export const unassignCandidateBulkUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.UNASSIGN_CANDIDATE),
        isBulk: z.literal(true),
        targetType: z.literal(activityTargetTypeSchema.Enum.APPLICATION),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            candidateCount: z.number(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
        }),
    })

export type UnassignCandidateBulkUserActivityLog = z.infer<
    typeof unassignCandidateBulkUserActivityLogSchema
>

export const assignExaminerUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.ASSIGN_EXAMINER),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            examinerName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
            groupNumber: z.string(),
        }),
    })

export type AssignExaminerUserActivityLog = z.infer<
    typeof assignExaminerUserActivityLogSchema
>

export const unassignExaminerUserActivityLogSchema =
    baseActivityLogSchema.extend({
        actionType: z.literal(activityActionTypeSchema.Enum.UNASSIGN_EXAMINER),
        isBulk: z.literal(false),
        targetId: z.string(),
        targetType: z.literal(activityTargetTypeSchema.Enum.USER),
        actorId: z.string(),
        actorType: z.literal(activityActorTypeSchema.Enum.USER),
        properties: z.object({
            examinerName: z.string(),
            eventName: z.string(),
            sessionDate: z.number(),
            sessionTime: z.number(),
            groupNumber: z.string(),
        }),
    })

export type UnassignExaminerUserActivityLog = z.infer<
    typeof unassignExaminerUserActivityLogSchema
>

export type ActivityLog =
    ({ type: 'InviteUserUserActivityLog' } & InviteUserUserActivityLog) |
    ({ type: 'DeleteUserUserActivityLog' } & DeleteUserUserActivityLog) |
    ({ type: 'EditPreferencesUserActivityLog' } & EditPreferencesUserActivityLog) |
    ({ type: 'AddApplicationManualUserActivityLog' } & AddApplicationManualUserActivityLog) |
    ({ type: 'AddApplicationCsvUserActivityLog' } & AddApplicationCsvUserActivityLog) |
    ({ type: 'EditCandidateInfoUserActivityLog' } & EditCandidateInfoUserActivityLog) |
    ({ type: 'UploadCandidateDocumentUserActivityLog' } & UploadCandidateDocumentUserActivityLog) |
    ({ type: 'DeleteCandidateDocumentUserActivityLog' } & DeleteCandidateDocumentUserActivityLog) |
    ({ type: 'SendCandidateEmailUserActivityLog' } & SendCandidateEmailUserActivityLog) |
    ({ type: 'SendCandidateEmailBulkUserActivityLog' } & SendCandidateEmailBulkUserActivityLog) |
    ({ type: 'SendCandidateEmailSystemActivityLog' } & SendCandidateEmailSystemActivityLog) |
    ({ type: 'ReceiveCandidateEmailSystemActivityLog' } & ReceiveCandidateEmailSystemActivityLog) |
    ({ type: 'UpdateApplicationStatusUserActivityLog' } & UpdateApplicationStatusUserActivityLog) |
    ({ type: 'UpdateApplicationStatusBulkUserActivityLog' } & UpdateApplicationStatusBulkUserActivityLog) |
    ({ type: 'UpdateApplicationStatusSystemActivityLog' } & UpdateApplicationStatusSystemActivityLog) |
    ({ type: 'UpdateApplicationStatusBulkSystemActivityLog' } & UpdateApplicationStatusBulkSystemActivityLog) |
    ({ type: 'ArchiveApplicationUserActivityLog' } & ArchiveApplicationUserActivityLog) |
    ({ type: 'ArchiveApplicationUserBulkActivityLog' } & ArchiveApplicationUserBulkActivityLog) |
    ({ type: 'UnarchiveApplicationUserActivityLog' } & UnarchiveApplicationUserActivityLog) |
    ({ type: 'UnarchiveApplicationUserBulkActivityLog' } & UnarchiveApplicationUserBulkActivityLog) |
    ({ type: 'DeleteCandidateUserActivityLog' } & DeleteCandidateUserActivityLog) |
    ({ type: 'DeleteCandidateUserBulkActivityLog' } & DeleteCandidateUserBulkActivityLog) |
    ({ type: 'FillEvaluationUserActivityLog' } & FillEvaluationUserActivityLog) |
    ({ type: 'FillEvaluationUserBulkActivityLog' } & FillEvaluationUserBulkActivityLog) |
    ({ type: 'AddJobUserActivityLog' } & AddJobUserActivityLog) |
    ({ type: 'DeleteJobUserActivityLog' } & DeleteJobUserActivityLog) |
    ({ type: 'EditJobUserActivityLog' } & EditJobUserActivityLog) |
    ({ type: 'SetEvaluationFormUserActivityLog' } & SetEvaluationFormUserActivityLog) |
    ({ type: 'EditEmailTemplateUserActivityLog' } & EditEmailTemplateUserActivityLog) |
    ({ type: 'SetEventUserActivityLog' } & SetEventUserActivityLog) |
    ({ type: 'EditEventUserActivityLog' } & EditEventUserActivityLog) |
    ({ type: 'AddEventSessionUserActivityLog' } & AddEventSessionUserActivityLog) |
    ({ type: 'EditEventSessionUserActivityLog' } & EditEventSessionUserActivityLog) |
    ({ type: 'AssignCandidateUserActivityLog' } & AssignCandidateUserActivityLog) |
    ({ type: 'AssignCandidateBulkUserActivityLog' } & AssignCandidateBulkUserActivityLog) |
    ({ type: 'UnassignCandidateUserActivityLog' } & UnassignCandidateUserActivityLog) |
    ({ type: 'UnassignCandidateBulkUserActivityLog' } & UnassignCandidateBulkUserActivityLog) |
    ({ type: 'AssignExaminerUserActivityLog' } & AssignExaminerUserActivityLog) |
    ({ type: 'UnassignExaminerUserActivityLog' } & UnassignExaminerUserActivityLog) |
    ({ type: 'UnknownActivityLog' } & BaseActivityLog)

export type ActivityLogType = ActivityLog['type'];

export const parseActivityLog = (data: BaseActivityLog): ActivityLog => {
    if (check(inviteUserUserActivityLogSchema, data)) {
        return { ...data, type: 'InviteUserUserActivityLog' as const }
    } else if (check(deleteUserUserActivityLogSchema, data)) {
        return { ...data, type: 'DeleteUserUserActivityLog' as const }
    } else if (check(editPreferencesUserActivityLogSchema, data)) {
        return { ...data, type: 'EditPreferencesUserActivityLog' as const }
    } else if (check(addApplicationManualUserActivityLogSchema, data)) {
        return { ...data, type: 'AddApplicationManualUserActivityLog' as const }
    } else if (check(addApplicationCsvUserActivityLogSchema, data)) {
        return { ...data, type: 'AddApplicationCsvUserActivityLog' as const }
    } else if (check(editCandidateInfoUserActivityLogSchema, data)) {
        return { ...data, type: 'EditCandidateInfoUserActivityLog' as const }
    } else if (check(uploadCandidateDocumentUserActivityLogSchema, data)) {
        return { ...data, type: 'UploadCandidateDocumentUserActivityLog' as const }
    } else if (check(deleteCandidateDocumentUserActivityLogSchema, data)) {
        return { ...data, type: 'DeleteCandidateDocumentUserActivityLog' as const }
    } else if (check(sendCandidateEmailUserActivityLogSchema, data)) {
        return { ...data, type: 'SendCandidateEmailUserActivityLog' as const }
    } else if (check(sendCandidateEmailBulkUserActivityLogSchema, data)) {
        return { ...data, type: 'SendCandidateEmailBulkUserActivityLog' as const }
    } else if (check(sendCandidateEmailSystemActivityLogSchema, data)) {
        return { ...data, type: 'SendCandidateEmailSystemActivityLog' as const }
    } else if (check(receiveCandidateEmailSystemActivityLogSchema, data)) {
        return { ...data, type: 'ReceiveCandidateEmailSystemActivityLog' as const }
    } else if (check(updateApplicationStatusUserActivityLogSchema, data)) {
        return { ...data, type: 'UpdateApplicationStatusUserActivityLog' as const }
    } else if (check(updateApplicationStatusBulkUserActivityLogSchema, data)) {
        return { ...data, type: 'UpdateApplicationStatusBulkUserActivityLog' as const }
    } else if (check(updateApplicationStatusSystemActivityLogSchema, data)) {
        return { ...data, type: 'UpdateApplicationStatusSystemActivityLog' as const }
    } else if (check(updateApplicationStatusBulkSystemActivityLogSchema, data)) {
        return { ...data, type: 'UpdateApplicationStatusBulkSystemActivityLog' as const }
    } else if (check(archiveApplicationUserActivityLogSchema, data)) {
        return { ...data, type: 'ArchiveApplicationUserActivityLog' as const }
    } else if (check(archiveApplicationUserBulkActivityLogSchema, data)) {
        return { ...data, type: 'ArchiveApplicationUserBulkActivityLog' as const }
    } else if (check(unarchiveApplicationUserActivityLogSchema, data)) {
        return { ...data, type: 'UnarchiveApplicationUserActivityLog' as const }
    } else if (check(unarchiveApplicationUserBulkActivityLogSchema, data)) {
        return { ...data, type: 'UnarchiveApplicationUserBulkActivityLog' as const }
    } else if (check(deleteCandidateUserActivityLogSchema, data)) {
        return { ...data, type: 'DeleteCandidateUserActivityLog' as const }
    } else if (check(deleteCandidateUserBulkActivityLogSchema, data)) {
        return { ...data, type: 'DeleteCandidateUserBulkActivityLog' as const }
    } else if (check(fillEvaluationUserActivityLogSchema, data)) {
        return { ...data, type: 'FillEvaluationUserActivityLog' as const }
    } else if (check(fillEvaluationUserBulkActivityLogSchema, data)) {
        return { ...data, type: 'FillEvaluationUserBulkActivityLog' as const }
    } else if (check(addJobUserActivityLogSchema, data)) {
        return { ...data, type: 'AddJobUserActivityLog' as const }
    } else if (check(deleteJobUserActivityLogSchema, data)) {
        return { ...data, type: 'DeleteJobUserActivityLog' as const }
    } else if (check(editJobUserActivityLogSchema, data)) {
        return { ...data, type: 'EditJobUserActivityLog' as const }
    } else if (check(setEvaluationFormUserActivityLogSchema, data)) {
        return { ...data, type: 'SetEvaluationFormUserActivityLog' as const }
    } else if (check(editEmailTemplateUserActivityLogSchema, data)) {
        return { ...data, type: 'EditEmailTemplateUserActivityLog' as const }
    } else if (check(setEventUserActivityLogSchema, data)) {
        return { ...data, type: 'SetEventUserActivityLog' as const }
    } else if (check(editEventUserActivityLogSchema, data)) {
        return { ...data, type: 'EditEventUserActivityLog' as const }
    } else if (check(addEventSessionUserActivityLogSchema, data)) {
        return { ...data, type: 'AddEventSessionUserActivityLog' as const }
    } else if (check(editEventSessionUserActivityLogSchema, data)) {
        return { ...data, type: 'EditEventSessionUserActivityLog' as const }
    } else if (check(assignCandidateUserActivityLogSchema, data)) {
        return { ...data, type: 'AssignCandidateUserActivityLog' as const }
    } else if (check(assignCandidateBulkUserActivityLogSchema, data)) {
        return { ...data, type: 'AssignCandidateBulkUserActivityLog' as const }
    } else if (check(unassignCandidateUserActivityLogSchema, data)) {
        return { ...data, type: 'UnassignCandidateUserActivityLog' as const }
    } else if (check(unassignCandidateBulkUserActivityLogSchema, data)) {
        return { ...data, type: 'UnassignCandidateBulkUserActivityLog' as const }
    } else if (check(assignExaminerUserActivityLogSchema, data)) {
        return { ...data, type: 'AssignExaminerUserActivityLog' as const }
    } else if (check(unassignExaminerUserActivityLogSchema, data)) {
        return { ...data, type: 'UnassignExaminerUserActivityLog' as const }
    } else {
        return { ...data, type: 'UnknownActivityLog' }
    }
}
