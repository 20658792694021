import { Candidate, CandidateAdd } from "@routes/candidate";
import { useNavigate } from "react-router-dom";

const useManualAddPage = () => {
    const navigate = useNavigate();

    const switchToCSVImport = () => {
        navigate(CandidateAdd.CSV_IMPORT_PATH_NAME);
    }

    const switchToTop = () => {
        navigate(Candidate.PATH_NAME)
    }

    return {
        switchToCSVImport,
        switchToTop
    }
}

export {
    useManualAddPage
}