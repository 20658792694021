import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useRouteQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export const toQueryString = (params: Record<string, string | undefined | null>) => {
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
        if (value) {
            searchParams.append(key, value);
        }
    }

    return searchParams.toString();
};