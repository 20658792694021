import { RefObject, useRef, useState } from 'react';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { ArchiveApplicationsDocument, RecruitmentStepStatus } from '@entities';
import { useMutation } from 'urql';
import { getQueryContext } from '@helpers/unsorted/urqlExtra';

type Mode = 'show' | 'hide';

interface ArchiveActionsHookResult {
    actionListDisplayMode: Mode;
    archiveActionsRef: RefObject<HTMLDivElement>;
    toggleArchiveActionList: VoidFunction;
    onOptionClick: (archiveAction: RecruitmentStepStatus, applicationId: string) => void;
}

const useArchiveActions = (): ArchiveActionsHookResult => {
    const [actionListDisplayMode, setActionListDisplayMode] = useState<Mode>('hide');

    const archiveActionsRef = useRef<HTMLDivElement>(null);

    const [, archiveApplications] = useMutation(ArchiveApplicationsDocument);

    const queryContext = getQueryContext([
        'RecruitmentProcess',
        'RecruitmentProcesses',
        'ApplicationApplicableAction',
        'OngoingRecruitmentProcessSummary',
        'ArchivedRecruitmentProcessSummary',
    ])

    useClickOutside([archiveActionsRef], () => setActionListDisplayMode('hide'));

    const toggleArchiveActionList = () => actionListDisplayMode === 'hide' ? setActionListDisplayMode('show') : setActionListDisplayMode('hide');

    const onOptionClick = (archiveAction: RecruitmentStepStatus, applicationId: string) => {
        archiveApplications({
            input: {
                applicationIds: [applicationId],
                reason: archiveAction
            }
        }, queryContext)

        setActionListDisplayMode('hide');
    };

    return {
        actionListDisplayMode,
        archiveActionsRef,
        toggleArchiveActionList,
        onOptionClick,
    };
};

export {
    Mode,
    useArchiveActions,
};
