import { UpdateCurrentClientDocument } from "@entities";
import { handleResponse, getQueryContext } from "@helpers/unsorted/urqlExtra";
import { useClient } from "@helpers/hooks/unsorted/clientHook";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useMutation, CombinedError } from "urql";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Encoder, Decoder } from "./AccountForm/value";

const formSubmitErrorCodeSchema = z.literal("wrong_password");

type FormSubmitErrorCode = z.TypeOf<typeof formSubmitErrorCodeSchema>;

const formSubmitErrorCodesFromCombinedError = (
    combinedError: CombinedError
): FormSubmitErrorCode[] => {
    return Array.from(
        combinedError.graphQLErrors.reduce((acc, error) => {
            const result = formSubmitErrorCodeSchema.safeParse(error.message);
            if (result.success) {
                acc.add(result.data);
            }
            return acc;
        }, new Set<FormSubmitErrorCode>())
    );
};

const useAccount = () => {
    const { currentClient } = useClient();
    const [updateCurrentClientResponse, updateCurrentClient] = useMutation(
        UpdateCurrentClientDocument
    );
    const { error: toastError, success: toastSuccess, info: toastInfo } = useToast();
    const currentClientInfo = useMemo(
        () =>
            currentClient.clientState === "loggedIn"
                ? currentClient.clientInfo
                : undefined,
        [currentClient]
    );
    const defaultFormInput: Encoder.Type = useMemo(
        () => Encoder.defaultValues(currentClientInfo),
        [currentClientInfo]
    );
    const form = useForm<Encoder.Type>({
        defaultValues: defaultFormInput,
        mode: "onBlur",
        reValidateMode: "onBlur",
        shouldFocusError: false,
    });
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            form.formState.isDirty &&
            currentLocation.pathname !== nextLocation.pathname
    );

    const handleReset = useCallback(() => {
        form.reset();
    }, [form]);

    const handleSubmit = useCallback(
        (input: Encoder.Type) => {
            if (!currentClientInfo) {
                return;
            }
            updateCurrentClient(
                {
                    ...Decoder.fromEncodedValue(input),
                    roleIds: currentClientInfo.roles.map((role) => role.id),
                },
                getQueryContext(["CurrentClient"])
            );
        },
        [currentClientInfo, updateCurrentClient]
    );

    const handleProceedBlocker = useCallback(() => {
        if (blocker.state === "blocked") {
            blocker.proceed();
        }
    }, [blocker]);

    const handleResetBlocker = useCallback(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
    }, [blocker]);

    const discardChanges = () => {
        handleProceedBlocker()
        toastInfo("settings.account.actions.discardNotification")
    }

    useEffect(() => {
        handleResponse(updateCurrentClientResponse, {
            onData: () => {
                handleProceedBlocker();
                toastSuccess("settings.account.actions.save.success");
            },
            onError: (error) => {
                const errorCodes = error
                    ? formSubmitErrorCodesFromCombinedError(error)
                    : [];
                for (const errorCode of errorCodes) {
                    if (errorCode === "wrong_password") {
                        form.setError("oldPassword", {
                            type: "wrongPassword",
                        });
                    }
                }
                toastError("settings.account.actions.save.error");
            },
        });
    }, [updateCurrentClientResponse, form.setError]);

    useLayoutEffect(() => {
        form.reset(defaultFormInput);
    }, [defaultFormInput]);

    return {
        currentClient,
        form,
        blocker,
        isSubmitting: updateCurrentClientResponse.fetching,
        handleReset,
        handleSubmit,
        handleProceedBlocker,
        handleResetBlocker,
        discardChanges,
    };
};

export { useAccount };
