import { AllRoless_AllRolesQuery, Client_OneByIdQuery } from '@entities';
import { validations } from '@helpers/unsorted/validation';
import { Button } from '@shared/unsorted/Button/Button';
import { CheckboxGroup } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { Input } from '@shared/unsorted/Input/Input';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styles } from '../UserInfoModal';
import { UserRelinquishPermissionConfirmModal } from './UserRelinquishPermissionConfirmationModal/UserRelinquishPermissionConfirmModal';
import { useEditable } from './hook';
import { UserInfoUpdateErrorModal } from './UserInfoUpdateErrorModal/UserInfoUpdateErrorModal';
import { Icon } from '@shared/unsorted/Icon/Icon';

interface Props {
    id: string;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    email: string;
    roles: Entity<Client_OneByIdQuery, 'client.roles'>[],
    close: VoidFunction,
    availableRoles: Entity<AllRoless_AllRolesQuery, 'roles'>[],
    isOnlyAdmin: boolean,
}

const EditableUserInfoModal: FunctionComponent<Props> = ({
    id,
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    email,
    roles,
    close,
    availableRoles,
    isOnlyAdmin,
}) => {
    const { t } = useTranslation();

    const {
        form,
        roleOptions,
        onSubmit,
        showConfirmation,
        rejectConfirmation,
        approveConfirmation,
        isLoading,
        relinquishedPermissionsState,
        showUpdateUserError,
        hideUpdateUserError,
    } = useEditable(
        id,
        firstName,
        lastName,
        firstNameKana,
        lastNameKana,
        email,
        roles,
        availableRoles,
        close,
        isOnlyAdmin,
    );

    const { clearErrors, control, formState: { errors } } = form;

    const onCloseModal = () => !showConfirmation && !showUpdateUserError && close();

    return (
        <Modal close={onCloseModal} isOpen className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}><Icon name="user" className={styles.icon} /></div>
                    <p className={styles.title}>{t("users.show.title")}</p>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
            </div>
            <form onSubmit={onSubmit} className={styles.bodyContainer}>
                <div className={styles.body}>
                    <div className={styles.form}>
                        <div className={styles.formRow}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.lastName"
                                        placeholder="users.edit.placeholders.lastName"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("lastName")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.firstName"
                                        placeholder="users.edit.placeholders.firstName"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("firstName")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.formRow}>
                            <Controller
                                name="lastNameKana"
                                control={control}
                                rules={{ required: true, pattern: validations.katakana }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.lastNameKana"
                                        placeholder="users.edit.placeholders.lastNameKana"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("lastNameKana")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name="firstNameKana"
                                control={control}
                                rules={{ required: true, pattern: validations.katakana }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.firstNameKana"
                                        placeholder="users.edit.placeholders.firstNameKana"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("firstNameKana")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    required
                                    name={name}
                                    label="users.edit.labels.email"
                                    placeholder="users.edit.placeholders.email"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("email")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.form}>
                        <Controller
                            name="roleIds"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <CheckboxGroup
                                    name="roleIds"
                                    label="users.create.labels.role"
                                    required
                                    onChange={onChange}
                                    value={value}
                                    options={roleOptions}
                                    errors={errors}
                                    tooltipClassName={styles.tooltip}
                                    tooltipIconClassName={styles.roleOptionsTooltipIcon}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button size="md" variant="secondary" onClick={onCloseModal} isFull>{t("global.cancel")}</Button>
                    {/* TODO: Fix. Need to disable save when form is not dirty. Need to debug why sometimes isDirty is not working properly when selected roles change */}
                    <Button size="md" type="submit" isLoading={isLoading} isFull>{t("users.edit.buttons.save")}</Button>
                </div>
            </form>
            <UserRelinquishPermissionConfirmModal
                isOpen={showConfirmation}
                onReject={rejectConfirmation}
                onConfirm={approveConfirmation}
                relinquishedPermissionsState={relinquishedPermissionsState}
            />
            <UserInfoUpdateErrorModal isOpen={showUpdateUserError} onClose={hideUpdateUserError} />
        </Modal>
    );
};

export {
    EditableUserInfoModal
};
