import { hasValue } from '@helpers/core/typeGuards';
import { Entity } from '@typedefs/graphql';
import { generatePath, useNavigate } from 'react-router-dom';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { JobPositionDetail } from '@routes/jobPosition';

interface ActionByStatusHookResult {
    moveToJobPosition: VoidFunction;
}

const useActionByStatus = (
    jobPosition: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.jobPosition'>,
): ActionByStatusHookResult => {
    const navigate = useNavigate();

    const moveToJobPosition = () => {
        const path = generatePath(JobPositionDetail.PATH_NAME, { id: jobPosition.id });

        navigate(path);
    };

    return {
        moveToJobPosition,
    };
};

export {
    useActionByStatus,
};