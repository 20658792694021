import { ActivityLogsDocument, EventSessionsDocument, EventSessionsQuery, UpdateCurrentClientShowWelcomeDocument } from
    '@entities';
import { useClient, ClientInfo } from '@helpers/hooks/unsorted/clientHook';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { handleResponse, useQueryContext } from '@helpers/unsorted/urqlExtra';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { ActivityLog, parseActivityLog } from '@helpers/core/activityLog';
import cookies, { WELCOME_MODAL_SHOWN_COOKIE } from '@helpers/core/cookies'
import { isBoolean } from '@helpers/core/typeGuards';
import { endOfWeek } from 'date-fns';
import { Entity } from '@typedefs/graphql';

interface HomePageHookType {
    clientInfo: ClientInfo | null;
    shouldDisplayWelcomeModal: boolean;
    onCloseWelcomeModal: (dontShowAgain: boolean) => void;
    eventSessions: Entity<EventSessionsQuery, 'eventSessions'>[]
    activityLogs: ActivityLog[];
}

const useHomePage = (): HomePageHookType => {
    const { currentClient } = useClient();

    const clientInfo = useMemo(() => currentClient.clientState === 'loggedIn' ? currentClient.clientInfo : null, [currentClient]);

    const { error: toastError } = useToast();

    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);

    const [updateShowWelcomeResponse, updateShowWelcome] = useMutation(UpdateCurrentClientShowWelcomeDocument);

    const [eventSessions, setEventSessions] = useState<Entity<EventSessionsQuery, 'eventSessions'>[]>([]);

    const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);

    const timeRange = useMemo(() => {
        const startAt = new Date();
        const endAt = endOfWeek(startAt, { weekStartsOn: 1 });

        return {
            startAt,
            endAt
        };
    }, []);

    const eventSessionsContext = useQueryContext('EventSession');
    const [eventSessionsResponse] = useQuery({
        query: EventSessionsDocument,
        context: eventSessionsContext,
        variables: {
            input: {
                startAt: timeRange.startAt.toISOString(),
                endAt: timeRange.endAt.toISOString(),
                first: 5,
                sortType: 'START_AT',
                order: 'asc'
            }
        }
    })

    const activityLogsContext = useQueryContext('ActivityLogs');
    const [activityLogsResponse] = useQuery({
        query: ActivityLogsDocument,
        context: activityLogsContext,
        variables: {
            input: {
                first: 20,
                offset: 0,
                order: 'asc',
            }
        }
    })

    const welcomeModalCookie = cookies.get(WELCOME_MODAL_SHOWN_COOKIE);
    const isWelcomeModalAlreadyShown = isBoolean(welcomeModalCookie) ? welcomeModalCookie : false;

    const onCloseAction = () => {
        cookies.set(WELCOME_MODAL_SHOWN_COOKIE, true);
        setIsWelcomeModalOpen(false);
    };

    const onCloseWelcomeModal = (isDontShowAgainChecked: boolean) => {
        if (currentClient.clientState === 'loggedIn' && (currentClient.clientInfo.showWelcome === isDontShowAgainChecked)) {
            // Only call the mutation if the value has changed
            updateShowWelcome({ showWelcome: !isDontShowAgainChecked });
        } else {
            onCloseAction();
        }
    };

    useEffect(() => {
        handleResponse(eventSessionsResponse, {
            onData: ({ eventSessions }) => {
                setEventSessions(eventSessions);
            }
        })
    }, [eventSessionsResponse]);

    useEffect(() => {
        handleResponse(updateShowWelcomeResponse, {
            onData: (data) => {
                if (data.updateCurrentClientShowWelcome) {
                    onCloseAction();
                }
            },
            onError: () => {
                // TODO: Verify how to handle this.
                // Still close the modal, but there is a possibility that it will be shown again even if don't show again is checked
                toastError('global.error');
                onCloseAction();
            },
        });
    }, [updateShowWelcomeResponse]);

    useEffect(() => {
        handleResponse(activityLogsResponse, {
            onData: (data) => {
                setActivityLogs(data.activityLogs.edges.map(edge => parseActivityLog({
                    ...edge,
                    actionTimestamp: new Date(edge.actionTimestamp)
                })));
            }
        })
    }, [activityLogsResponse]);

    return {
        clientInfo,
        shouldDisplayWelcomeModal: currentClient.clientState === 'loggedIn' && currentClient.clientInfo.showWelcome
            && !isWelcomeModalAlreadyShown && isWelcomeModalOpen,
        onCloseWelcomeModal,
        eventSessions,
        activityLogs,
    };
};

export {
    useHomePage,
};
