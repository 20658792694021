import { Client_OneByIdQuery } from '@entities';
import { isDefined } from '@helpers/core/typeGuards';
import { getRoleKey } from '@pages/SettingsPage/hook';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Label } from '@shared/unsorted/Label/Label';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from '../UserInfoModal';
import { useReadOnlyUserInfoModal } from './hook';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { useClient } from '@helpers/hooks/unsorted/clientHook';
import { Chip } from '@shared/unsorted/Chip/Chip';

interface Props {
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    email: string;
    roles: Entity<Client_OneByIdQuery, 'client.roles'>[],
    clientType: Entity<Client_OneByIdQuery, 'client.__typename'>
    close: VoidFunction;

}

const ReadOnlyUserInfoModal: FunctionComponent<Props> = ({ firstName, lastName, firstNameKana, lastNameKana, email, roles, close, clientType }) => {
    const { t } = useTranslation();

    const { tooltips } = useReadOnlyUserInfoModal();

    const { currentClient } = useClient();

    const getDescription = () => {
        if (currentClient.clientState != 'loggedIn') {
            return undefined;
        }

        if (clientType === 'RegisteredClient') {
            // TODO: Update JP translation for scovilleUserDescription
            return currentClient.clientInfo.isScovilleUser ? t('users.show.scovilleUserDescription') : t('users.show.description');
        }

        return (currentClient.clientInfo.isScovilleAdmin
            || currentClient.clientInfo.isScovilleUser
            || currentClient.clientInfo.isAdmin)
            ? t('users.show.invitedDescription')
            : t('users.show.description');
    };

    return (
        <Modal close={close} isOpen className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}><Icon name="user" className={styles.icon} /></div>
                    <p className={styles.title}>{t("users.show.title")}</p>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={close} />
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.body}>
                    <p className={styles.description}> {getWithDefault(getDescription())} </p>
                    {clientType === 'RegisteredClient' && <>
                        <div className={styles.infoRow}>
                            <div className={styles.infoSection}>
                                <Label label={"users.show.labels.lastName"} />
                                <p className={styles.textInfo}> {getWithDefault(lastName)} </p>
                            </div>
                            <div className={styles.infoSection}>
                                <Label label={"users.show.labels.firstName"} />
                                <p className={styles.textInfo}> {getWithDefault(firstName)} </p>
                            </div>
                        </div>
                        <div className={styles.infoRow}>
                            <div className={styles.infoSection}>
                                <Label label={"users.show.labels.lastNameKana"} />
                                <p className={styles.textInfo}> {getWithDefault(lastNameKana)} </p>
                            </div>
                            <div className={styles.infoSection}>
                                <Label label={"users.show.labels.firstNameKana"} />
                                <p className={styles.textInfo}> {getWithDefault(firstNameKana)} </p>
                            </div>
                        </div>
                    </>}
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.email"} />
                        <p className={styles.textInfo}> {email} </p>
                    </div>
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.roles"} />
                        <div className={styles.roles}>
                            {roles.map(({ name }) =>
                                <ToolTipBox
                                    tooltipContent={isDefined(tooltips[name]) ? tooltips[name] : ""}
                                    placement="bottom"
                                    toolTipClassName={styles.tooltip}

                                >
                                    <div>
                                        <Chip
                                            variant="outline"
                                            iconName="info"
                                            label={t(`users.role.${getRoleKey(name)}`)}
                                        />
                                    </div>
                                </ToolTipBox>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <Button size="md" variant="secondary" onClick={close} isFull>
                    {t("global.close")}
                </Button>
            </div>
        </Modal >
    );
};

export {
    ReadOnlyUserInfoModal
};
