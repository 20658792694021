import { Toaster } from '@shared/unsorted/Toaster/Toaster';
import { StrictMode } from 'react';

import { App } from './App';
import { GraphqlProvider } from '../../../contexts/GraphqlContext';
import { ClientProvider } from '../../../contexts/ClientContext';
import { useRoot } from './rootHook';
import { Loading } from '@shared/core/Loading/Loading';

const Root = () => {
    const { clientState, graphqlClient, loadingProgress, showLoadingScreen } = useRoot()

    return (
        <GraphqlProvider client={graphqlClient}>
            <ClientProvider value={clientState}>
                <StrictMode>
                    {showLoadingScreen && <Loading progress={loadingProgress} />}
                    <App />
                    <Toaster />
                </StrictMode>
            </ClientProvider>
        </GraphqlProvider>
    );
};

export {
    Root,
};
