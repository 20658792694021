import { Trans } from "react-i18next";
import { assertNever } from '@helpers/typeHelpers';
import { ActivityLog } from '@helpers/core/activityLog';

export type Props = {
  activityLog: ActivityLog;
}

// TODO: Replace with real translations
export const renderActivityLogMessage = (log: ActivityLog): React.ReactNode => {
  switch (log.type) {
    case 'InviteUserUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'DeleteUserUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditPreferencesUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AddApplicationManualUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AddApplicationCsvUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditCandidateInfoUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UploadCandidateDocumentUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'DeleteCandidateDocumentUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'SendCandidateEmailUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'SendCandidateEmailBulkUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'SendCandidateEmailSystemActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'ReceiveCandidateEmailSystemActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UpdateApplicationStatusUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UpdateApplicationStatusBulkUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UpdateApplicationStatusSystemActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UpdateApplicationStatusBulkSystemActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'ArchiveApplicationUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'ArchiveApplicationUserBulkActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnarchiveApplicationUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnarchiveApplicationUserBulkActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'DeleteCandidateUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'DeleteCandidateUserBulkActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'FillEvaluationUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'FillEvaluationUserBulkActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AddJobUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'DeleteJobUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditJobUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'SetEvaluationFormUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditEmailTemplateUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'SetEventUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditEventUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AddEventSessionUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'EditEventSessionUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AssignCandidateUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AssignCandidateBulkUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnassignCandidateUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnassignCandidateBulkUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'AssignExaminerUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnassignExaminerUserActivityLog':
      return <Trans i18nKey="analytics" />;
    case 'UnknownActivityLog':
      return <Trans i18nKey="analytics" />;
    default:
      assertNever(log)
  }
};

export const ActivityLogMessage: React.FunctionComponent<Props> = ({ activityLog }) => {
  return (
    <>
      {renderActivityLogMessage(activityLog)}
    </>
  )
}
