import { AllRoless_AllRolesQuery, Client_OneByIdQuery } from '@entities';
import { isDefined } from '@helpers/core/typeGuards';
import { extractClientRoles, useClient } from '@helpers/hooks/unsorted/clientHook';
import { Cn } from '@helpers/unsorted/classNames';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { EditableUserInfoModal } from './EditableUserInfoModal/EditableUserInfoModal';
import { ReadOnlyUserInfoModal } from './ReadOnlyUserInfoModal/ReadOnlyUserInfoModal';
import { useUserInfoModal } from './hook';

const styles = {
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    header: Cn.c('flex justify-between items-start pb-5 pt-6 px-6 border-b border-b-default shrink-0'),
    titleContainer: Cn.c('flex flex-col space-y-4'),
    title: Cn.c('font-h4-bold text-emphasized'),
    iconContainer: Cn.c('p-3 w-fit border border-default rounded'),
    icon: Cn.c('w-6 h-6 text-icons-emphasized'),
    body: Cn.c('p-6 space-y-6 border-b border-default overflow-y-auto'),
    description: Cn.c('font-paragraph-small-regular text-subdued'),
    infoSection: Cn.c('space-y-0.5 w-full'),
    textInfo: Cn.c('font-paragraph-small-regular text-default'),
    roles: Cn.c('flex flex-wrap gap-2'),
    questionMark: Cn.c('w-3.5 h-3.5 text-icons-primary-default'),
    buttons: Cn.c('flex items-center justify-between space-x-3 p-6'),
    bodyContainer: Cn.c('flex flex-col min-h-0'),
    form: Cn.c('text-default font-paragraph-small-medium'),
    formRow: Cn.c('flex flex-row justify-between flex-1 space-x-4'),
    infoRow: Cn.c('flex justify-between space-x-4'),
    tooltip: Cn.c('w-fit'),
    roleOptionsTooltipIcon: Cn.c('w-4 h-4 text-icons-subdued'),
    modal: Cn.c('flex flex-col'),
};

interface Props {
    availableRoles: Entity<AllRoless_AllRolesQuery, 'roles'>[];
    client?: Entity<Client_OneByIdQuery, 'client'>;
    isOnlyAdmin: boolean;
}

// @ocaml.doc(
//   "The modal component that decides whether to show the form to edit the selected client or the read only form."
// )
const UserInfoModal: FunctionComponent<Props> = ({
    availableRoles,
    client,
    isOnlyAdmin,
}) => {
    const { currentClient } = useClient();

    const { closeModal } = useUserInfoModal();

    if (currentClient.clientState === 'loggedIn' && isDefined(client)) {
        const { clientInfo: currentClientInfo } = currentClient;

        const scovilleOnlyRoles = ['Scoville', 'ScovilleAdmin'];
        const userAvailableRoles = availableRoles.filter(role => !scovilleOnlyRoles.includes(role.name));

        if (client.__typename === 'RegisteredClient') {
            const {
                id,
                firstName,
                lastName,
                firstNameKana,
                lastNameKana,
                email,
                roles,
            } = client;
            
            if (currentClientInfo.isScovilleAdmin) {
                return (
                    <EditableUserInfoModal
                        id={id}
                        firstName={firstName}
                        lastName={lastName}
                        firstNameKana={firstNameKana}
                        lastNameKana={lastNameKana}
                        email={email}
                        roles={roles}
                        availableRoles={availableRoles}
                        close={closeModal}
                        isOnlyAdmin={isOnlyAdmin}
                    />
                );
            } else if (currentClientInfo.isScovilleUser) {
                const clientRoles = extractClientRoles(client.roles);
                if (clientRoles.isScovilleAdmin) {
                    return (
                        <ReadOnlyUserInfoModal
                            clientType={client.__typename}
                            firstName={firstName}
                            lastName={lastName}
                            firstNameKana={firstNameKana}
                            lastNameKana={lastNameKana}
                            email={email}
                            roles={roles}
                            close={closeModal}
                        />
                    );
                } else {
                    return (
                        <EditableUserInfoModal
                            id={id}
                            firstName={firstName}
                            lastName={lastName}
                            firstNameKana={firstNameKana}
                            lastNameKana={lastNameKana}
                            email={email}
                            roles={roles}
                            availableRoles={availableRoles.filter(role => role.name !== 'ScovilleAdmin')}
                            close={closeModal}
                            isOnlyAdmin={isOnlyAdmin}
                        />
                    );
                }
            } else if (currentClientInfo.isAdmin) {
                return (
                    <EditableUserInfoModal
                        id={id}
                        firstName={firstName}
                        lastName={lastName}
                        firstNameKana={firstNameKana}
                        lastNameKana={lastNameKana}
                        email={email}
                        roles={roles}
                        availableRoles={userAvailableRoles}
                        close={closeModal}
                        isOnlyAdmin={isOnlyAdmin}
                    />
                );
            } else {
                return (
                    <ReadOnlyUserInfoModal
                        clientType={client.__typename}
                        firstName={firstName}
                        lastName={lastName}
                        firstNameKana={firstNameKana}
                        lastNameKana={lastNameKana}
                        email={email}
                        roles={roles}
                        close={closeModal}
                    />
                );
            }
        }

        if (client.__typename === 'InvitedClient') {
            const { email, roles } = client;

            return (
                <ReadOnlyUserInfoModal
                    clientType={client.__typename}
                    email={email}
                    roles={roles}
                    close={closeModal}
                />
            );
        }
    }

    // Comment from rescript code
    // TODO: Handle cancel invitation here

    return null;
};

export {
    UserInfoModal,
    styles
};
