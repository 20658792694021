import { UserManagement } from '@routes/setting';
import { useNavigate } from 'react-router-dom';

interface UserInfoModalHookResult {
    closeModal: VoidFunction;
}

const useUserInfoModal = (): UserInfoModalHookResult => {
    const navigate = useNavigate();

    const queryParams = UserManagement.useSearchParams();

    const closeModal = () => navigate(UserManagement.toRoute({
        ...queryParams,
        mode: 'list',
        id: '',
    }));

    return {
        closeModal,
    };
};

export {
    useUserInfoModal
};
