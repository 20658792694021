import { allClosedStepStatuses, noSetupUpdateStatusBulkActions, updateStatusBulkActions } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { splitToChunks } from "@helpers/unsorted/arrayExtra";
import { useBulkActionModalContext } from "@pages/ApplicationsPage/BulkActionModalContext";
import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext";
import { isAssignToEventSessionGroupProperty, SelectedApplications } from "@shared/application/bulkActions/helpers";
import { MenuGroupData } from "@shared/unsorted/NestedMenu/NestedMenu";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const BULK_ACTION_ID_SIZE = 150;

const useBulkActionMenu = () => {
    const {
        applicationSelection,
    } = useApplicationSelectionContext()

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const { t } = useTranslation();

    const { availableBulkActions, unavailableBulkActions } = Object.entries(applicationSelection)
        .reduce<{ availableBulkActions: [(typeof updateStatusBulkActions)[number], SelectedApplications][], unavailableBulkActions: [string, SelectedApplications][] }>(
            (acc, [action, value]) => {
                if (isOneOf(updateStatusBulkActions)(action)) {
                    acc.availableBulkActions.push([action, value]);
                } else {
                    acc.unavailableBulkActions.push([action, value]);
                }

                return acc;
            }, { availableBulkActions: [], unavailableBulkActions: [] }
        );

    const availableActionIds = availableBulkActions.flatMap(([, ids]) => Object.keys(ids));
    const unavailableActionIds = unavailableBulkActions.flatMap(([, ids]) => Object.keys(ids));
    const noBulkActionItemsCount = unavailableActionIds.filter((id) => !availableActionIds.includes(id)).length;

    const archivedApplicationIds = Object.keys(applicationSelection['REVERT_TO_PREVIOUS_STATUS'] || {});
    const activeApplicationIds = Object.keys(applicationSelection['ARCHIVE'] || {});

    const noPayloadUpdateStatusBulkActionMenu: MenuGroupData = {
        items: availableBulkActions
            .filter(([action]) => isOneOf(noSetupUpdateStatusBulkActions)(action))
            .map(([action, ids]) => {
                const allIds = Object.keys(ids);
                const label = `${t(`recruitmentStepStatusAction.${action}`)} (${allIds.length})`;
                const value = action;

                return {
                    label,
                    value,
                }
            })
    }

    if (noBulkActionItemsCount > 0) {
        noPayloadUpdateStatusBulkActionMenu.items.push({
            label: `${t('applications.list.table.noBulkAction')} (${noBulkActionItemsCount})`,
            value: 'NO_ACTION',
            isDisabled: true,
        })
    }

    const archiveActionMenu: MenuGroupData = {
        items: allClosedStepStatuses.map((closedStepStatus) => ({
            label: `${t(`recruitmentStepStatusV2.${closedStepStatus}`)}  (${activeApplicationIds.length})`,
            value: closedStepStatus,
        }))
    }

    const assignToSessionStepIds = useMemo(() => Object
        .values(applicationSelection['ASSIGN_TO_SESSION_MANUALLY'] || {})
        .reduce((acc: Record<string, true>, property) => {
            if (isAssignToEventSessionGroupProperty(property)) {
                acc[property.stepId] = true;
            }

            return acc
        }, {}), [applicationSelection])

    const bulkActionMenu: MenuGroupData[] = [
        {
            items: [
                {
                    label: t('applications.bulkAction.updateStepStatus'),
                    value: 'UPDATE_STEP_STATUS',
                    children: noPayloadUpdateStatusBulkActionMenu.items.length > 0 ? [noPayloadUpdateStatusBulkActionMenu] : [],
                    isDisabled: activeApplicationIds.length === 0,
                    tooltip: activeApplicationIds.length !== 0
                        ? undefined
                        : t('applications.bulkAction.noActiveCandidateSelection')
                },
                {
                    label: t('applications.bulkAction.assignToSession'),
                    value: 'ASSIGN_TO_SESSION_MANUALLY',
                    isDisabled: Object.keys(assignToSessionStepIds).length !== 1,
                    tooltip: Object.keys(assignToSessionStepIds).length !== 1
                        ? t('applications.bulkAction.noCandidateInSameJobAndStep')
                        : undefined
                },
            ]
        },
        {
            items: [
                {
                    label: t('applications.bulkAction.archive'),
                    value: 'ARCHIVE',
                    children: [archiveActionMenu],
                    isDisabled: activeApplicationIds.length === 0,
                    tooltip: activeApplicationIds.length !== 0
                        ? undefined
                        : t('applications.bulkAction.noActiveCandidateSelection')
                },
                {
                    label: t('applications.bulkAction.revertToActive'),
                    value: 'REVERT_TO_PREVIOUS_STATUS',
                    isDisabled: archivedApplicationIds.length === 0,
                    tooltip: archivedApplicationIds.length !== 0
                        ? undefined
                        : t('applications.bulkAction.noArchivedCandidateSelection')
                }
            ]
        }
    ]

    const onBulkActionChange = (value: string) => {
        if (value === 'REVERT_TO_PREVIOUS_STATUS') {
            showRevertToSelectionConfirmationModal()
        }

        if (isOneOf(allClosedStepStatuses)(value)) {
            showBulkArchiveConfirmationModal(value)
        }

        if (isOneOf(noSetupUpdateStatusBulkActions)(value)) {
            showBulkActionGeneralConfirmationModal(value)
        }

        if (value === 'ASSIGN_TO_SESSION_MANUALLY') {
            const applications = applicationSelection['ASSIGN_TO_SESSION_MANUALLY']

            if (!applications) {
                return
            }

            const numOfApplicationsAssignedToSession = Object
                .values(applications)
                .reduce((acc, property) => {
                    if (isAssignToEventSessionGroupProperty(property)) {
                        acc += property.currentEventSessionGroupAssignments.length > 0 ? 1 : 0
                    }

                    return acc
                }, 0)

            if (numOfApplicationsAssignedToSession > 0) {
                showAssignToSessionOptionModal(numOfApplicationsAssignedToSession)
                return
            }
            showAssignToSessionModal()
        }
    }

    const showBulkActionGeneralConfirmationModal = (action: typeof noSetupUpdateStatusBulkActions[number]) => {
        const applications = applicationSelection[action];

        if (!applications) {
            return;
        }

        const applicationIds = Object.keys(applications);
        const applicationIdChunks = splitToChunks(applicationIds, BULK_ACTION_ID_SIZE);
        dispatchBulkActionModalMode({
            name: 'showBulkActionGeneralConfirmationModal',
            payload: {
                action,
                applicationIdBatches: applicationIdChunks,
            }
        })
    }

    const showBulkArchiveConfirmationModal = (archiveReason: typeof allClosedStepStatuses[number]) => {
        const applicationIdChunks = splitToChunks(activeApplicationIds, BULK_ACTION_ID_SIZE);
        dispatchBulkActionModalMode({
            name: 'showBulkActionGeneralConfirmationModal',
            payload: {
                action: 'ARCHIVE',
                applicationIdBatches: applicationIdChunks,
                bulkActionPayload: { type: 'archive', archiveReason }
            }
        })
    }

    const showRevertToSelectionConfirmationModal = () => {
        const applicationIdChunks = splitToChunks(archivedApplicationIds, BULK_ACTION_ID_SIZE);
        dispatchBulkActionModalMode({
            name: 'showBulkActionGeneralConfirmationModal',
            payload: {
                action: 'REVERT_TO_PREVIOUS_STATUS',
                applicationIdBatches: applicationIdChunks,
            }
        })
    }

    const showAssignToSessionModal = () => {
        const applications = applicationSelection['ASSIGN_TO_SESSION_MANUALLY'];

        if (!applications) {
            return;
        }

        const applicationIds = Object.keys(applications);
        dispatchBulkActionModalMode({
            name: 'showBulkEventSessionAssignmentModal',
            payload: {
                applicationIds,
                recruitmentStepId: Object.keys(assignToSessionStepIds)[0]
            }
        })
    }


    const showAssignToSessionOptionModal = (numOfApplicationsAssignedToSession: number) => {
        const applications = applicationSelection['ASSIGN_TO_SESSION_MANUALLY'];

        if (!applications) {
            return;
        }

        dispatchBulkActionModalMode({
            name: 'showBulkEventSessionAssignmentOptionModal',
            payload: {
                recruitmentStepId: Object.keys(assignToSessionStepIds)[0],
                numOfApplicationsAssignedToSession,
                totalApplications: Object.values(applications).length
            }
        })
    }

    return {
        bulkActionMenu,
        onBulkActionChange,
    }
}

export {
    BULK_ACTION_ID_SIZE,
    useBulkActionMenu
}